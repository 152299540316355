<template>
  <div class="animated fadeIn">
    <b-card>
      <div>
        <h4 class="d-inline"><i class="fa fa-users"></i> All System Admins</h4>
        <router-link class="float-right btn btn-success" :to="{ name: 'Admin Add System Admin' }">Add System Admin <i
                class="fa fa-plus"></i></router-link>
        <!-- Change to Sys Admin -->
        <!-- <router-link
          class="float-right btn btn-success"
          :to="{ name: 'Admin Add Manager', params: { id: $route.params.id } }"
          >Add System Admin <i class="fa fa-plus"></i
        ></router-link> -->
      </div>
      <hr class="my-4" />
      <v-client-table :columns="columns" :data="sysAdminData" :options="options" :theme="theme" id="dataTable">
        <div slot="status" slot-scope="props">
          <span v-if="props.row.status === 0">
            <button class="btn btn-sm btn-success">Active</button>
          </span>
          <span v-if="props.row.status !== 0">
            <button class="btn btn-sm btn-danger">Inactive</button>
          </span>
        </div>
        <span slot="creation_date" slot-scope="props">{{
            props.row.creation_date | moment("M/D/YYYY")
        }}</span>
        <div slot="actions" slot-scope="props" data-boundary="window">
          <b-button class="mr-2 btn-sm" @click="sendInfo(props.row)"><i class="fa fa-edit"></i> Edit</b-button>
        </div>
      </v-client-table>

      <h4 class="mt-4"><i class="nav-icon icon-user"></i> Company Profile</h4>
      <hr />
      <b-table striped stacked :fields="fields" :items="companyData">
        <template slot="creation_date" slot-scope="data">
          {{ data.item.creation_date | moment("M/D/YYYY") }}
        </template>
      </b-table>
    </b-card>





    <b-modal id="editManager" ref="editModal" title="Edit System Admin" title-tag="h2" centered size="lg" hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <label>First Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control"
            placeholder="First Name" />
        </b-input-group>
        <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')"
          class="help is-danger">The first name is required.</span>
        <label>Last Name</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control"
            placeholder="Last Name" />
        </b-input-group>
        <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')"
          class="help is-danger">The last name is required.</span>
        <hr />
        <label>Phone Number</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone"
            class="form-control" placeholder="Phone Number" />
        </b-input-group>
        <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')"
          class="help is-danger">{{
              errors.first("phone")
          }}</span>

        <label>Fax Number</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-fax"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="number" v-model="fax" v-validate="'digits:10'" name="fax" class="form-control"
            placeholder="Fax Number" />
        </b-input-group>
        <i v-show="errors.has('fax')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fax')"
          class="help is-danger">{{
              errors.first("fax")
          }}</span>

        <hr />
        <label>Email</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="email" v-model="email" v-validate="'required'" name="email" class="form-control"
            placeholder="Email" />
        </b-input-group>
        <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('email')"
          class="help is-danger">The email is required.</span>

        <b-input-group class="mt-3 mb-2">
          <b-form-checkbox class="ml-2 mt-1" v-model="status" value="0" unchecked-value="1">
            <p class="ml-3 mb-0">
              Account <span v-if="status == 0">Active</span><span v-if="status != 0"> Inactive</span>
            </p>
          </b-form-checkbox>
        </b-input-group>

        <i v-show="error" class="fa fa-exclamation-triangle ml-2 mt-3 mb-2"></i><span v-show="error"
          class="help is-danger">Error. Please contact admin.</span>

        <b-button variant="success" class="mt-4" type="submit" block>Edit</b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
import vueSlider from "vue-slider-component";

import AccountantAuthority from "./AccountantAuthority";

Vue.use(ClientTable);

export default {
  name: "AllCompanies",
  components: {
    ClientTable,
    Event,
    vueSlider,
    AccountantAuthority,
  },
  data() {
    return {
      companyData: [],
      columns: [
        "status",
        "email",
        "fname",
        "lname",
        "phone",
        "creation_date",
        "actions",
      ],
      sysAdminData: [],
      accountantData: [],
      options: {
        orderBy: { ascending: true },
        headings: {
          fname: "First Name",
          lname: "Last Name",
        },
        sortable: [
          "email",
          "fname",
          "lname",
          "status",
          "fax",
          "phone",
          "creation_date",
        ],
        filterable: [
          "email",
          "fname",
          "lname",
          "status",
          "fax",
          "phone",
          "creation_date",
        ],
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
        pagination: {
          chunk: 1,
          edge: false,
          nav: "scroll",
        },
      },
      fields: [
        ,
        {
          key: "name",
          label: "Company Name",
        },
        {
          key: "rcn",
          label: "Registration Number",
        },
        {
          key: "gst",
          label: "GST Number",
        },
        {
          key: "addressline1",
          label: "Address",
        },
        {
          key: "addressline2",
          label: "Address 2",
        },
        {
          key: "city",
          label: "City",
        },
        {
          key: "postalcode",
          label: "Postal Code",
        },
        {
          key: "province",
          label: "Province",
        },
        // {
        //   key: 'phone',
        //   label: 'Phone',
        // },
        // {
        //   key: 'fax',
        //   label: 'Fax',
        // },
        {
          key: "creation_date",
          label: "Creation Date",
        },
      ],
      theme: "bootstrap4",
      template: "default",
      useVuex: false,
      id: "",
      fname: "",
      lname: "",
      email: "",
      authority: 1,
      passlimit: "",
      fax: "",
      status: "",
      phone: "",
      error: "",
      auth: 50,
    };
  },
  methods: {
    sendInfo(row) {
      this.id = row.managerid;
      this.fname = row.fname;
      this.lname = row.lname;
      this.email = row.email;
      this.status = row.status;
      this.authority = row.authority;
      this.passlimit = row.passlimit;
      this.fax = row.fax;
      this.phone = row.phone;

      this.$refs.editModal.show();
    },
    sendManagerId(id) {
      this.id = id;
    },
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            id: this.id,
            companyid: this.$route.params.id,
            email: this.email,
            fname: this.fname,
            lname: this.lname,
            fax: this.fax,
            phone: this.phone,
            status: this.status,
          });

          this.$http
            .post("/admin/edit/sys_admin", data)
            .then((response) => {
              if (response.status === 200) {
                this.$toasted.show("Update Successful.", {
                  type: "success",
                  duration: "3000",
                });
                this.$refs.editModal.hide();
                this.loadData();
              }
            })
            .catch((error) => {
              this.$toasted.show("Error.", { type: "error", duration: "3000" });
              this.error = true;
            });
          return;
        }
      });
    },
    loadData() {
      this.$http
        .get("/admin/get/sys_admin")
        .then((response) => {
          if (response.status === 200) {
            this.sysAdminData = response.data.result;
          }
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });
      console.log("id :", this.$route.params.id);
      this.$http
        .get("/admin/get/company/" + this.$route.params.id)
        .then((response) => {
          if (response.status === 200) {
            this.companyData = [response.data.result];
            console.log("🚀 ~ file: Company.vue ~ line 348 ~ .then ~ this.companyData = [...response];", this.companyData)
          }
        })
        .catch((error) => {
          this.$toasted.show("Error.", { type: "error", duration: "3000" });
        });


    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>
