var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c("h4", [
        _c("i", { staticClass: "nav-icon icon-layers" }),
        _vm._v(" Authority Level\n\n    "),
        _c(
          "select",
          {
            staticStyle: { float: "right" },
            on: {
              change: function($event) {
                return _vm.loaddata($event)
              }
            }
          },
          [
            _c("option", { attrs: { value: "1" } }, [_vm._v("Managers")]),
            _c("option", { attrs: { value: "2" } }, [
              _vm._v("Project Managers")
            ])
          ]
        )
      ]),
      _c("hr"),
      _c("v-client-table", {
        attrs: {
          columns: _vm.authorityType == 1 ? _vm.columns : _vm.columns2,
          data: _vm.wtsData,
          options: _vm.options,
          theme: _vm.theme,
          id: "dataTable"
        },
        scopedSlots: _vm._u([
          {
            key: "type",
            fn: function(props) {
              return _c("div", {}, [
                props.row.authority
                  ? _c("span", [
                      _vm._v(
                        "\n        Manager (" +
                          _vm._s(props.row.authority) +
                          ")\n      "
                      )
                    ])
                  : _c("span", [_vm._v(" Project Manager ")])
              ])
            }
          },
          {
            key: "passlimit",
            fn: function(props) {
              return _c("div", {}, [_vm._v("$" + _vm._s(props.row.passlimit))])
            }
          },
          {
            key: "actions",
            fn: function(props) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.editModal",
                          modifiers: { editModal: true }
                        }
                      ],
                      attrs: { size: "sm", variant: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.sendId(
                            props.row.authority,
                            props.row.wtsid,
                            props.row.managerid,
                            props.row.passlimit
                          )
                        }
                      }
                    },
                    [
                      _vm._v("Edit Level "),
                      _c("i", { staticClass: "fa fa-edit" })
                    ]
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "b-modal",
        {
          ref: "editModal",
          attrs: {
            id: "editModal",
            title: "Edit Limit",
            "title-tag": "h2",
            centered: "",
            size: "lg",
            "hide-footer": ""
          },
          on: {
            shown: function($event) {
              _vm.error = false
            }
          }
        },
        [
          _c(
            "form",
            {
              staticClass: "pb-2",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateBeforeSubmit($event)
                }
              }
            },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Pass Limit")]),
              _c(
                "b-input-group",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c("b-input-group-text", [
                        _c("i", { staticClass: "fa fa-dollar-sign" })
                      ])
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "passlimit",
                      placeholder: "$100000"
                    },
                    model: {
                      value: _vm.passlimit,
                      callback: function($$v) {
                        _vm.passlimit = $$v
                      },
                      expression: "passlimit"
                    }
                  })
                ],
                1
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("passlimit"),
                    expression: "errors.has('passlimit')"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("passlimit"),
                      expression: "errors.has('passlimit')"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("The pass limit is required.")]
              ),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.error,
                    expression: "error"
                  }
                ],
                staticClass: "fa fa-exclamation-triangle mt-3"
              }),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.error,
                      expression: "error"
                    }
                  ],
                  staticClass: "help is-danger"
                },
                [_vm._v("Error.\n        Please contact admin.\n      ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-4",
                  attrs: { variant: "success", type: "submit", block: "" }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }