<template>
  <b-card>
    <h4>
      <i class="nav-icon icon-layers"></i> Authority Level

      <select style="float: right" @change="loaddata($event)">
        <option value="1">Managers</option>
        <option value="2">Project Managers</option>
      </select>
    </h4>
    <hr />
    <v-client-table :columns="authorityType == 1 ? columns : columns2" :data="wtsData" :options="options" :theme="theme"
      id="dataTable">
      <div slot="type" slot-scope="props">
        <span v-if="props.row.authority">
          Manager ({{ props.row.authority }})
        </span>
        <span v-else> Project Manager </span>
      </div>
      <div slot="passlimit" slot-scope="props">${{ props.row.passlimit }}</div>

      <div slot="actions" slot-scope="props">
        <b-button size="sm" v-b-modal.editModal variant="primary" @click="
          sendId(
            props.row.authority,
            props.row.wtsid,
            props.row.managerid,
            props.row.passlimit
          )
        ">Edit Level <i class="fa fa-edit"></i></b-button>
      </div>
    </v-client-table>
    <b-modal id="editModal" @shown="error = false" ref="editModal" title="Edit Limit" title-tag="h2" centered size="lg"
      hide-footer>
      <form @submit.prevent="validateBeforeSubmit" class="pb-2">
        <label for="">Pass Limit</label>
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-input-group-text><i class="fa fa-dollar-sign"></i></b-input-group-text>
          </b-input-group-prepend>
          <b-form-input type="text" v-validate="'required'" name="passlimit" v-model="passlimit" class="form-control"
            placeholder="$100000" />
        </b-input-group>
        <i v-show="errors.has('passlimit')" class="fa fa-exclamation-triangle"></i><span
          v-show="errors.has('passlimit')" class="help is-danger">The pass limit is required.</span>

        <i v-show="error" class="fa fa-exclamation-triangle mt-3"></i><span v-show="error" class="help is-danger">Error.
          Please contact admin.
        </span>
        <b-button variant="success" class="mt-4" type="submit" block>Save</b-button>
      </form>
    </b-modal>
  </b-card>
</template>

<script>
import Vue from "vue";
import { ClientTable, Event } from "vue-tables-2";
Vue.use(ClientTable);

export default {
  name: "AccountantAuthority",
  components: {
    ClientTable,
    Event,
  },
  props: ["companyid"],
  data() {
    return {
      authorityType: 1,
      columns: [
        // "managerid",
        "email",
        "authority",
        "fname",
        "lname",
        "passlimit",
        "phone",
        "actions",
      ],
      columns2: [
        "email",
        "fname",
        "lname",
        "passlimit",
        "actions",
      ],
      wtsData: [],
      options: {
        headings: {
          type: "Type (Authority Level)",
          // managerid: "ID",
          email: "Email",
          authority: "Authority",
          fname: "First Name",
          lname: "Last Name",
          passlimit: "Pass Limit",
          phone: "Phone",
        },
        sortable: ["authority"],
        filterable: false,
        sortIcon: {
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
          is: "fa-sort",
        },
      },
      theme: "bootstrap4",
      template: "default",
      useVuex: false,
      id: "",
      error: false,
      projectData: [],
      projectid: "0",
      unique: false,
      assignerror: false,
      passlimit: 0,
      loading: false,
      wtsid: 0,
      managerid: 0,
    };
  },
  methods: {
    validateBeforeSubmit() {
      // this.$validator.validateAll().then((result) => {
      let data = JSON.stringify({
        managerid: this.managerid,
        wtsid: this.wtsid,
        passlimit: this.passlimit,
        companyid: this.companyid,
      });
      this.$http
        .post("/admin/authority/updateMangerPasslimit", data)
        .then((response) => {
          this.$refs.editModal.hide();
          location.reload();
          this.loaddata();
        })
        .catch((error) => {
          this.error = true;
        });
    },

    sendId(authority, wtsid, managerid, passlimit) {
      this.authority = authority;
      this.wtsid = wtsid;
      this.managerid = managerid;
      this.passlimit = passlimit;
    },
    async loaddata(e) {
      this.loading = false;
      this.authorityType = e.target ? e.target.value : 1;
      if (this.authorityType == 1) {
        const { data } = await this.$http.get(
          `/admin/authority?companyid=${this.companyid}`
        );
        this.wtsData = data.rows;
      } else {
        const { data } = await this.$http.get(
          `/admin/authority/getProjectManagers?companyid=${this.companyid}`
        );
        this.wtsData = data.rows;
        console.log('i ma here');
      }
    },
    // loadProjects() {
    //   this.$http.get('/manager/get/projects')
    //   .then(response => {
    //     this.projectData = response.data.result;
    //   })
    //   .catch(error => {
    //     this.$toasted.show('Error.', {type: 'error', duration: '3000'})
    //   })
    // }
  },
  mounted: function () {
    this.loaddata(2);
  },
};
</script>
